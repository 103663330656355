const Affiliate = () => import('@/views/account/Friends/Affiliate.vue')
const AffiliateForm = () => import('@/views/account/Friends/AffiliateForm.vue')

const AffiliateSuccess = () => import('@/views/account/Friends/AffiliateSuccess.vue')

const Friends = () => import('@/views/account/Friends/FriendsPage.vue')
const FriendDetail = () => import('@/views/account/Friends/FriendDetail.vue')

export default [
    {
        path: '/affiliate',
        component: Affiliate,
        name: 'affiliate',
        meta: {
            requiresAuth: true,
            layout: 'AccountLayout',
            breadcrumbs: {
                name: 'Affiliate Program',
                additionalClass: 'bc-horizontal',
                excerpt: 'Earn money by referring people to our platform. Choose your affiliate package!'
            }
        }
    },
    {
        path: '/affiliate/sign-up',
        component: AffiliateForm,
        name: 'affiliate-signup',
        meta: {
            requiresAuth: true,
            layout: 'AccountLayout'
        }
    },
    {
        path: '/affiliate/success',
        component: AffiliateSuccess,
        name: 'affiliate-success',
        meta: {
            requiresAuth: true,
            layout: 'AccountLayout'
        }
    },
    {
        path: '/referrals',
        component: Friends,
        name: 'friends',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'My Friends',
                excerpt: 'Affiliate statistics and my friends.',
                additionalClass: 'bc-horizontal'
            }
        }
    },
    {
        path: '/referrals/:id',
        component: FriendDetail,
        name: 'friend_detail',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Friend’s profile',
                additionalClass: 'bc-wrapper bc-horizontal',
                parent_route_names: ['friends']
            }
        }
    }
]
