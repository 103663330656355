<template>
    <div
        v-if="!isLoading"
        id="app"
    >
        <transition
            name="fade"
        >
            <component
                :is="layout"
                v-if="layout"
            />
        </transition>
        <global-modals />
        <Notifier v-if="$socket.isConnected && $route.name !== 'checkout-processing'" />
        <SupportMessenger v-if="layout !== 'MessengerLayout' && $route.name !== 'checkout-processing'" />
        <!-- <update-notifications /> -->
        <!-- <modal-account-fill-notificatons /> -->
    </div>
</template>

<script>

import GlobalModals from '@/components/GlobalModals.vue'
import BreadcrumbsLayout from '@/layouts/BreadcrumbsLayout.vue'
import AccountLayout from '@/layouts/AccountLayout.vue'
import CustomBreadcrumbsLayout from '@/layouts/CustomBreadcrumbsLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import SupportMessenger from '@/components/support/supportMessenger/SupportMessenger'
// import UpdateNotifications from '@/components/update-notifications/Main.vue'

// mixins
import authMixin from '@/mixins/global/authMixin.js'
import pwaMixin from '@/mixins/global/pwaMixin.js'
import loggedInUserMixin from '@/mixins/global/loggedInUserMixin.js'

import { eventBus } from '@/helpers/event-bus/'
import { getSettings } from '@/services/settings/'
import MessengerLayout from '@/layouts/MessengerLayout.vue'
import Notifier from '@/components/Notifier.vue'
import moment from 'moment/moment';
import { setUTMMedium, setUTMSource, setUTMCampaign } from '@/services/utm'
// import ModalAccountFillNotificatons from '@/components/modals/ModalAccountFillNotificatons.vue'

export default {
    components: {
        AccountLayout,
        BreadcrumbsLayout,
        CustomBreadcrumbsLayout,
        EmptyLayout,
        MessengerLayout,
        GlobalModals,
        SupportMessenger,
        Notifier
        // ModalAccountFillNotificatons
        // UpdateNotifications
    },
    mixins: [
        authMixin,
        pwaMixin,
        loggedInUserMixin
    ],
    metaInfo: {
        title: 'Home',
        titleTemplate: 'Dashboard | %s'
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        layout() {
            return this.$route.meta.layout
        }
    },
    async created() {
        // offline listener
        window.addEventListener('offline', () => {
            const errorString = 'There is a problem with the internet connection, try refreshing the page please.'
            eventBus.$emit('showSnackBar', errorString, 'error')
        })
        try {
            this.isLoading = true
            await getSettings()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        } finally {
            this.isLoading = false
        }
        await this.getBackendVersion()
        this.adsCheck()
    },
    methods: {
        async getBackendVersion() {
            try {
                const resp = await fetch(`${process.env.VUE_APP_API_URL}/api/data/api-version`)
                const data = await resp.json();
                console.group('FRONTEND')
                console.log(
                    '%c environment ',
                    'color: #fff; font-style: italic; background-color: #5E35B1; padding: 3px 3px; border-radius: 4px;',
                    ` ${process.env.VUE_APP_ENV} `
                );
                console.log(
                    '%c version ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 3px 3px; border-radius: 4px;',
                    ` ${process.env.VUE_APP_VERSION} `
                );
                console.log(
                    '%c build time ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 3px 3px; border-radius: 4px; ',
                    ` ${moment(process.env.VUE_APP_BUILD_DATETIME).format('YY-MM-DD h:mm')} (${moment(moment(process.env.VUE_APP_BUILD_DATETIME)).fromNow(true)}) `
                );
                console.groupEnd()
                console.group('BACKEND')
                console.log(
                    '%c environment ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.env} `
                );
                console.log(
                    '%c version ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.commit_hash} `
                );
                console.log(
                    '%c build time ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.date_of_build}, (${data.updated_when})`
                );
                console.groupEnd()
            } catch (e) {
                console.error(e);
            }
        },
        adsCheck() {
            const { utm_medium, utm_source, utm_campaign } = this.$route.query
            if (utm_medium) setUTMMedium(utm_medium)
            if (utm_source) setUTMSource(utm_source)
            if (utm_campaign) setUTMCampaign(utm_campaign)
        }
    },
    head: {
        meta: [
            { property: 'og:image', content: `/img/icons/${process.env.VUE_APP_DOMAIN}/og_image.png` }
        ]
    }
}
</script>
