const AccSettingMain = () => import('@/views/account/Setting/Main')
const AccSettingPayMethod = () => import('@/views/account/Setting/PayMethod')
const AccSettingEmail = () => import('@/views/account/Setting/Email')
const AccSettingPassword = () => import('@/views/account/Setting/Password')
const AccSettingPhone = () => import('@/views/account/Setting/Phone')
const AccSettingAddress = () => import('@/views/account/Setting/Address')
const AccSettingWriters = () => import('@/views/account/Setting/Writers')
const AccSettingNotifications = () => import('@/views/account/Setting/Notifications')
const AccSetting2FA = () => import('@/views/account/Setting/TwoFa')
const AccSettingName = () => import('@/views/account/Setting/Name')
const AccAffiliate = () => import('@/views/account/Setting/Affiliate')
const AffiliatePaymentsAddMethod = () => import('@/views/account/Setting/AffiliatePaymentsAddMethod')
export default [
    {
        path: '/settings',
        component: AccSettingMain,
        name: 'profile',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Settings',
                additionalClass: 'bc-wrapper bc-horizontal '
            }
        }
    },
    {
        path: '/settings/payment-method',
        component: AccSettingPayMethod,
        name: 'profile_payment-method',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Withdrawal Method',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/email',
        component: AccSettingEmail,
        name: 'profile_email',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Email',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/name',
        component: AccSettingName,
        name: 'profile_name',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change account name',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/password',
        component: AccSettingPassword,
        name: 'profile_password',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Password',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/phone',
        component: AccSettingPhone,
        name: 'profile_phone',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Address',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/address',
        component: AccSettingAddress,
        name: 'profile_address',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Address',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/writers',
        component: AccSettingWriters,
        name: 'profile_writers',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Writer Preferences',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/notifications',
        component: AccSettingNotifications,
        name: 'profile_notifications',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Notification settings',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/two-fa',
        component: AccSetting2FA,
        name: 'profile_twofa',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Two-Factor authentication settings',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/affiliate',
        component: AccAffiliate,
        name: 'profile_affiliate',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Affiliate',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    },
    {
        path: '/settings/affiliate-add-payment-method',
        component: AffiliatePaymentsAddMethod,
        name: 'affiliate-add-payment-method',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Affiliate add payment-method',
                additionalClass: 'bc-wrapper bc-horizontal ',
                parent_route_names: ['profile'],
                hasBack: true
            }
        }
    }
]
