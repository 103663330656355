import { render, staticRenderFns } from "./AccountLayout.vue?vue&type=template&id=21fc1564&scoped=true"
import script from "./AccountLayout.vue?vue&type=script&lang=js"
export * from "./AccountLayout.vue?vue&type=script&lang=js"
import style0 from "./AccountLayout.vue?vue&type=style&index=0&id=21fc1564&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21fc1564",
  null
  
)

export default component.exports